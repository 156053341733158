import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllLocations } from '../pages/visit/visitApi';
import { STATUS } from '../constants/fetchStatus';

export const getLocations = createAsyncThunk('locationSlice/allLocation', async () => {
  return (await getAllLocations()).data;
});

const initialState = {
  locationHierarchy: {
    id: null,
    nameBangla: '',
    nameEnglish: '',
    type: null,
    children: [],
  },
  error: null,
  status: STATUS.IDLE,
};

const locationSlice = createSlice({
  name: 'locationSlice',
  initialState,
  reducers: {
    resetLocation(state) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLocations.pending, (state) => {
        state.status = STATUS.LOADING;
        state.error = null;
      })
      .addCase(getLocations.fulfilled, (state, action) => {
        state.status = STATUS.SUCCESS;
        state.locationHierarchy = action.payload;
        state.error = null;
      })
      .addCase(getLocations.rejected, (state, action) => {
        state.status = STATUS.ERROR;
        state.error = action;
      });
  },
});

export const { resetLocation } = locationSlice.actions;

export const locationReducer = (state) => state.allLocation;

export default locationSlice.reducer;
