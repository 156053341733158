import React, { useEffect, useState } from 'react';
import { getJurisdictionWiseZones, toDropdownItems } from '../../utils/helperFunction';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { authInfo } from '../../pages/auth/authSlice';
import { generalReducer } from '../../redux/generalSlice';
import { locationReducer } from '../../redux/locationSlice';
import Button from './Button';
import { appLanguages } from '../../constants/appConstants';
import AppMultiSelect from './AppMultiSelect';

const AppReportFilter = ({
  children,
  hideFilterClear,
  filterClassName,
  actionColSize = 12,
  actionCustomContent,
  disableFilterButton,
  clearFilterClassName,
  isZoneSelectionRequired = false,
  isDistrictSelectionRequired = false,
  isUpazilaSelectionRequired = false,
  hideDistrictDropdown = false,
  hideUpazilaDropdown = false,
  onFilterClick = () => {},
  onClearFilter = () => {},
  shouldUseJurisdiction = true,
}) => {
  const tCommon = useTranslation().t;
  const { user } = useSelector(authInfo);
  const { activeJurisdiction } = user;
  const { appLanguage } = useSelector(generalReducer);
  const { locationHierarchy } = useSelector(locationReducer);

  const [selectedZonesId, setSelectedZonesId] = useState([]);
  const [selectedDistrictsId, setSelectedDistrictsId] = useState([]);
  const [selectedUpazilasId, setSelectedUpazilasId] = useState([]);

  const [zones, setZones] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [upazilas, setUpazilas] = useState([]);

  const [zoneSelectItems, setZoneSelectItems] = useState([]);
  const [districtSelectItems, setDistrictSelectItems] = useState([]);
  const [upazilaSelectItems, setUpazilaSelectItems] = useState([]);

  const getSelectItemLable = (item) => {
    let label = '';
    if (appLanguage === appLanguages.Bengali) {
      label += item['parentNameBangla'] + ' ' + tCommon(item.parentType.toLowerCase());
      label += '>' + item['nameBangla'];
    } else {
      label += item['parentNameEnglish'] + tCommon(item.parentType.toLowerCase());
      label += ' > ' + item['nameEnglish'];
    }
    label += ' ' + tCommon(item.type.toLowerCase());
    return label;
  };

  const prepareSelectItems = (items = []) => {
    let results = [];

    items.forEach((e) => {
      results.push({
        value: e['id'],
        label: getSelectItemLable(e),
      });
    });

    return results;
  };

  const checkIfSelectedUpazilaRemovedOrNot = (currentUpazilas = []) => {
    const newSelectedIds = selectedUpazilasId.filter((ele) =>
      currentUpazilas.some((dis) => +dis.id === +ele.value),
    );
    setSelectedUpazilasId(newSelectedIds);
  };

  const checkIfSelectedDistrictRemovedOrNot = (currentDistricts = []) => {
    const newSelectedIds = selectedDistrictsId.filter((ele) =>
      currentDistricts.some((dis) => +dis.id === +ele.value),
    );
    setSelectedDistrictsId(newSelectedIds);
    let upazilaList = [];

    //update upazila
    currentDistricts.forEach((element) => {
      if (newSelectedIds.some((item) => +item.value === +element.id)) {
        upazilaList = [...upazilaList, ...element.children];
      }
    });

    upazilaList = upazilaList.map((upa) => {
      const districtId = +upa.path.split('/')[2];
      const districtObj = currentDistricts.filter((dis) => +dis.id === districtId)[0];
      return {
        ...upa,
        parentId: districtObj.id,
        parentNameBangla: districtObj.nameBangla,
        parentNameEnglish: districtObj.nameEnglish,
        parentType: districtObj.type,
      };
    });
    setUpazilas(upazilaList);
    setUpazilaSelectItems(prepareSelectItems(upazilaList));

    checkIfSelectedUpazilaRemovedOrNot(upazilaList);
  };

  const handleZoneChange = (zoneItems = []) => {
    setSelectedZonesId(zoneItems);
    let districtList = [];

    zones.forEach((element) => {
      if (zoneItems.some((item) => +item.value === +element.id)) {
        districtList = [...districtList, ...element.children];
      }
    });

    districtList = districtList.map((dis) => {
      const zoneId = +dis.path.split('/')[1];
      const zoneObj = zones.filter((zone) => +zone.id === zoneId)[0];
      return {
        ...dis,
        parentId: zoneObj.id,
        parentNameBangla: zoneObj.nameBangla,
        parentNameEnglish: zoneObj.nameEnglish,
        parentType: zoneObj.type,
      };
    });
    if (shouldUseJurisdiction && districtList.length) {
      const districtId = activeJurisdiction.districtId;
      if (districtId) {
        districtList = districtList.filter((dis) => dis.id === districtId);
      }
    }
    setDistricts(districtList);
    setDistrictSelectItems(prepareSelectItems(districtList));
    checkIfSelectedDistrictRemovedOrNot(districtList);
  };

  const handleDistrictChange = (districtItems = []) => {
    setSelectedDistrictsId(districtItems);

    let upazilaList = [];

    districts.forEach((element) => {
      if (districtItems.some((item) => +item.value === +element.id)) {
        upazilaList = [...upazilaList, ...element.children];
      }
    });

    upazilaList = upazilaList.map((upa) => {
      const districtId = +upa.path.split('/')[2];
      const districtObj = districts.filter((dis) => +dis.id === districtId)[0];
      return {
        ...upa,
        parentId: districtObj.id,
        parentNameBangla: districtObj.nameBangla,
        parentNameEnglish: districtObj.nameEnglish,
        parentType: districtObj.type,
      };
    });

    if (shouldUseJurisdiction && upazilaList.length) {
      const upazilaId = activeJurisdiction.upazillaId;
      if (upazilaId) {
        upazilaList = upazilaList.filter((upa) => upa.id === upazilaId);
      }
    }

    setUpazilas(upazilaList);
    setUpazilaSelectItems(prepareSelectItems(upazilaList));

    checkIfSelectedUpazilaRemovedOrNot(upazilaList);
  };

  const handleUpazilaChange = (upazilaItems = []) => {
    setSelectedUpazilasId(upazilaItems);
  };

  const handleFilterClick = () => {
    const extractedZones = zones
      .filter((zone) => selectedZonesId.some((ele) => +ele.value === +zone.id))
      .map((zone) => ({
        id: zone.id,
        nameBangla: zone.nameBangla,
        nameEnglish: zone.nameEnglish,
        path: zone.path,
      }));

    const extractedDistricts = districts
      .filter((dis) => selectedDistrictsId.some((ele) => +ele.value === +dis.id))
      .map((dis) => ({
        id: dis.id,
        nameBangla: dis.nameBangla,
        nameEnglish: dis.nameEnglish,
        path: dis.path,
      }));

    const extractedUpazilas = upazilas
      .filter((upa) => selectedUpazilasId.some((ele) => +ele.value === +upa.id))
      .map((upa) => ({
        id: upa.id,
        nameBangla: upa.nameBangla,
        nameEnglish: upa.nameEnglish,
        path: upa.path,
      }));

    onFilterClick(extractedZones, extractedDistricts, extractedUpazilas);
  };

  const handleClearFilter = () => {
    onClearFilter();
    if (zoneSelectItems.length > 1) {
      setSelectedZonesId([]);
    }

    if (districtSelectItems.length > 1) {
      setSelectedDistrictsId([]);
      setDistrictSelectItems([]);
      setDistricts([]);
    }
    if (upazilaSelectItems.length > 1) {
      setSelectedUpazilasId([]);
      setUpazilaSelectItems([]);
      setUpazilas([]);
    }
  };

  const shouldDisableFilter = () => {
    let isDisable = disableFilterButton;
    if (isZoneSelectionRequired && !selectedZonesId.length) {
      isDisable = true;
    }
    if (isDistrictSelectionRequired && !selectedDistrictsId.length) {
      isDisable = true;
    }
    if (isUpazilaSelectionRequired && !selectedUpazilasId.length) {
      isDisable = true;
    }
    return isDisable;
  };

  useEffect(() => {
    const extractedZones = getJurisdictionWiseZones(
      locationHierarchy,
      shouldUseJurisdiction ? activeJurisdiction.path : '/',
    );
    setZones([...extractedZones]);
    const zoneSelectItems = toDropdownItems(
      extractedZones,
      appLanguage,
      'id',
      'nameBangla',
      'nameEnglish',
    );

    setZoneSelectItems(zoneSelectItems);
  }, [appLanguage, activeJurisdiction.path, locationHierarchy, shouldUseJurisdiction]);

  return (
    <div className='mb-4'>
      {children && <div className='row mx-0 app-report-filter_container'>{children}</div>}

      <div className='row mx-0 app-report-filter_container'>
        <div>
          <div>{tCommon('zone')}</div>
          <AppMultiSelect
            AllSelectLabel={tCommon('allZone')}
            value={selectedZonesId}
            onChange={handleZoneChange}
            options={zoneSelectItems}
            placeholder={tCommon('selectZone')}
            noOptionsMessage={() => tCommon('allZoneHasSelected')}
          />
        </div>
      </div>

      <div className={`row mx-0 app-report-filter_container ${hideDistrictDropdown && 'd-none'}`}>
        <div>
          <div>{tCommon('district')}</div>
          <AppMultiSelect
            AllSelectLabel={tCommon('allDistrict')}
            value={selectedDistrictsId}
            onChange={handleDistrictChange}
            options={districtSelectItems}
            disabled={!selectedZonesId.length}
            placeholder={tCommon('selectDistrict')}
            noOptionsMessage={() => tCommon('allDistrictHasSelected')}
          />
        </div>
      </div>
      <div className={`row mx-0 app-report-filter_container ${hideUpazilaDropdown && 'd-none'}`}>
        <div>
          <div>{tCommon('subDistrict')}</div>
          <AppMultiSelect
            AllSelectLabel={tCommon('allUpazila')}
            value={selectedUpazilasId}
            onChange={handleUpazilaChange}
            options={upazilaSelectItems}
            disabled={!selectedDistrictsId.length}
            placeholder={tCommon('selectSubDistrict')}
            noOptionsMessage={() => tCommon('allUpazilaHasSelected')}
          />
        </div>
      </div>

      <div
        className={`col-${actionColSize} d-flex justify-content-end gap-2 `}
        style={{ marginTop: '1.5rem' }}
      >
        {!hideFilterClear && (
          <div>
            <Button
              className={`report__filter-clear__button ${clearFilterClassName}`}
              onClick={handleClearFilter}
            >
              {tCommon('removeFilter')}
            </Button>
          </div>
        )}
        <div>
          <Button
            className={`filter-btn  ${filterClassName} ${shouldDisableFilter() && 'disable-btn'}`}
            onClick={handleFilterClick}
            disabled={shouldDisableFilter()}
          >
            {actionCustomContent ? actionCustomContent : tCommon('prepareReport')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AppReportFilter;
