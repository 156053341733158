import React, { useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import profile_picture from '../../../src/assets/images/profile_picture.png';
import dms_logo from '../../../src/assets/icons/gov_bd_logo.svg';
import bell_icon from '../../../src/assets/icons/bell_icon.svg';
import { appLanguages, localizationNS, menuItems } from '../../constants/appConstants';
import { authInfo } from '../../pages/auth/authSlice';
import '../index.css';
import { logout } from '../../services/logoutService';
import { appRoutes } from '../../constants/appRoutes';
import { profile_icon, logout_icon, hamburger_menu, cross_white_icon } from '../../assets';
import { generalReducer } from '../../redux/generalSlice';
import dropdown_icon_white from '../../../src/assets/icons/dropdown_icon_white.svg';
import { getUserNotification } from '../../pages/notice/NotificationSlice';
import { fetchUserNotification } from '../../pages/notice/notificationApi';
import { digitConversion } from '../../utils/helperFunction';

const Topbar = ({ handleClick, showMenu, setActiveMenu }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { appLanguage } = useSelector(generalReducer);
  const { t } = useTranslation(localizationNS.common);

  const {
    user: { fullNameBangla, fullNameEnglish, designationBangla, designationEnglish, userId },
  } = useSelector(authInfo);

  const unreadCount = useSelector(getUserNotification)
    .notifications.filter((n) => n.userId === userId)
    .reduce((count, notification) => {
      if (notification.isVisible && !notification.isRead) {
        return count + 1;
      } else {
        return count;
      }
    }, 0);

  useEffect(() => {
    dispatch(fetchUserNotification());

    const FETCH_INTERVAL_MIN = process.env.REACT_APP_NOTICE_FETCH_INTERVAL_MIN || 10;
    const intervalId = setInterval(
      () => dispatch(fetchUserNotification()),
      parseInt(FETCH_INTERVAL_MIN) * 60 * 1000,
    );

    return () => clearInterval(intervalId);
  }, [dispatch]);

  return (
    <div className='dms-primary-color sticky-top'>
      <div className='topbar-container'>
        {/* logo */}
        <div className='fs-2 fw-bold d-flex align-items-center gap-2 pointer'>
          <span onClick={handleClick}>
            <img
              src={hamburger_menu}
              className={showMenu ? 'd-none' : ' d-lg-none d-inline-block'}
            />
            <img
              src={cross_white_icon}
              className={showMenu ? 'd-lg-none d-inline-block' : 'd-none'}
            />
          </span>
          <div
            className='d-flex align-items-center gap-2 '
            onClick={() => navigate(appRoutes.dashboard)}
          >
            <img src={dms_logo} className='img-fluid' alt='DMS logo' />
            <span className='logo-text'>DMS</span>
            <span className='logo-small-text'>
              Digital Monitoring <br /> System
            </span>
          </div>
        </div>

        <div className='d-flex align-items-center'>
          <div className='notification-div'>
            <img
              src={bell_icon}
              className='nav-bell-icon img-fluid pointer'
              alt='bell-icon'
              onClick={() => {
                setActiveMenu(menuItems.noticeList);
                navigate(appRoutes.noticeList);
              }}
            />

            {unreadCount ? (
              <div className='notification-badge'>{digitConversion(unreadCount, appLanguage)}</div>
            ) : (
              ''
            )}
          </div>
          <Dropdown>
            <Dropdown.Toggle>
              <div className='d-flex align-items-center gap-3'>
                <img
                  src={profile_picture}
                  className='nav-profile-img rounded-circle img-fluid '
                  alt='Profile picture'
                />
                <div className='d-flex flex-column '>
                  <span className='name-text'>
                    {appLanguage === appLanguages.Bengali ? fullNameBangla : fullNameEnglish}
                  </span>
                  <span className='designation-text'>
                    {appLanguage === appLanguages.Bengali ? designationBangla : designationEnglish}
                  </span>
                </div>{' '}
                <img src={dropdown_icon_white} className=' img-fluid ' alt='Profile picture' />
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className='mt-3'>
              <Dropdown.Item href='#'>
                <img src={profile_icon} />
                <span className='pt-1'>{t('topbar.profile')}</span>
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  dispatch(logout());
                }}
              >
                <img src={logout_icon} />

                <span className='pt-1'>{t('topbar.logout')} </span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
