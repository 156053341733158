import React from 'react';
import { TooltipContextProvider, useTooltip } from '../../hooks/useTooltip';
import Tooltip from './Tooltip';

const defaultColors = ['#0061F0', '#FD9843', '#4895ef', '#3f37c9'];

const DonutChart = ({
  items = [
    { value: 25, label: 'Stocks' },
    { value: 5, label: 'Cash' },
    { value: 25, label: 'Crypto' },
  ],
  roundedCaps = false,
  size = 'lg',
  trackWidth = 'md',
  trackColor = '#141517',
  showCenterText = true,
  centerTopText = '',
  centerBottomText = '',
  centerMiddleText = '',
  centerMiddleTextColor = 'black',
  centerOtherTextcolor = 'black',
  centerMiddleFontSize = '40px',
  centerOtherFontSize = '40px',
  backgroundTooltipColor = '#023047',
  tooltipFontSize = '18px',
  tooltipColor = 'white',
  tooltipSx = {},
  totalSx = {},
}) => {
  let currPercentTotal = 0;

  /**
   * box size of chart
   */
  let sz;
  let tw;
  let vb;

  switch (size) {
    case 'sm':
      sz = '216';
      break;
    case 'md':
      sz = '316';
      break;
    case 'lg':
      sz = '400';
      break;
    default:
      sz = `${size}`;
  }

  switch (trackWidth) {
    case 'sm':
      tw = '3';
      vb = '0 0 36 36';
      break;
    case 'md':
      tw = '5';
      vb = '0 0 38 38';
      break;
    case 'lg':
      tw = '7';
      vb = '0 0 40 40';
      break;
    default:
      tw = `${trackWidth}`;
  }

  return (
    <TooltipContextProvider>
      <svg width={sz} height={sz}>
        <svg viewBox={vb}>
          <circle
            cx='50%'
            cy='50%'
            r='15.91549430918954'
            fill='none'
            stroke={trackColor}
            strokeWidth={tw}
            strokeLinecap='round'
            strokeDasharray='100 0'
            strokeDashoffset='25'
          />
          {items.map((item, index) => {
            const offSet = 25 - currPercentTotal;
            currPercentTotal += item.value;

            return (
              <DonutItem
                key={index}
                value={item.value}
                displayValue={item.displayValue || `${item.value}% ${item.label}`}
                color={item?.color || defaultColors[index % defaultColors.length]}
                trackWidth={tw}
                roundedCaps={roundedCaps}
                offSet={offSet}
              />
            );
          })}
        </svg>
        {showCenterText && (
          <>
            <text
              textAnchor='middle'
              dominantBaseline='middle'
              x='50%'
              y='35%'
              fill={centerOtherTextcolor}
              fontSize='14px' // Adjust the font size as needed
              style={{ ...totalSx }}
            >
              {centerTopText}
            </text>
            <text
              textAnchor='middle'
              dominantBaseline='middle'
              x='50%'
              y='50%'
              fill={centerMiddleTextColor}
              fontSize={centerMiddleFontSize}
              style={{ ...totalSx }}
            >
              {centerMiddleText}
            </text>
            <text
              textAnchor='middle'
              dominantBaseline='middle'
              x='50%'
              y='65%'
              fill={centerOtherFontSize}
              fontSize='14px' // Adjust the font size as needed
              style={{ ...totalSx }}
            >
              {centerBottomText}
            </text>
          </>
        )}
      </svg>
      <Tooltip
        bgColor={backgroundTooltipColor}
        fontSize={tooltipFontSize}
        textColor={tooltipColor}
        style={{ ...tooltipSx }}
      />
    </TooltipContextProvider>
  );
};

const DonutItem = ({ value, displayValue, roundedCaps, color, trackWidth, offSet }) => {
  const dashArr = (value) => {
    let adjustPercent = value;
    if (roundedCaps) {
      adjustPercent = value === 100 ? value : value >= 2 ? value - 1 : 0;
    }
    return `${adjustPercent} ${100 - adjustPercent}`;
  };

  const { showTooltip, hideTooltip } = useTooltip();

  return (
    <circle
      style={{ transition: 'stroke 1s ease-in-out' }}
      cx='50%'
      cy='50%'
      r='15.91549430918954'
      strokeLinecap={roundedCaps ? 'round' : 'inherit'}
      fill='none'
      stroke={color}
      strokeWidth={trackWidth}
      strokeDasharray={dashArr(value)}
      strokeDashoffset={offSet}
      onMouseOver={(ref) => showTooltip(ref.pageX + 40, ref.pageY - 20, displayValue)}
      onMouseLeave={() => hideTooltip()}
      cursor='pointer'
    />
  );
};

export default DonutChart;
