import React, { Fragment } from 'react';

const Dropdown = ({
  title,
  value,
  selectItems = [],
  handleChange = () => {},
  disabled,
  error,
  errorMessage,
  disabledTitle,
  ...rest
}) => {
  return (
    <Fragment>
      <select
        className={`form-select rounded-2 ${error && 'border-danger'} select-dropdown`}
        disabled={disabled}
        onChange={(e) => handleChange(e.target.value)}
        value={value}
        {...rest}
      >
        <option value={''} disabled={disabledTitle}>
          {title}
        </option>
        {selectItems.map((ele) => (
          <option key={ele.value} value={ele.value}>
            {ele.label}
          </option>
        ))}
      </select>
      {error && <div className='text-danger'>{errorMessage}</div>}
    </Fragment>
  );
};

export default Dropdown;
