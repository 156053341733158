import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

const AppSpinner = ({
  animation = 'border',
  variant = 'dark',
  className = 'backdrop--loader',
  ...rest
}) => {
  return (
    <div className={className}>
      <Spinner animation={animation} role='status' variant={variant} {...rest} />{' '}
    </div>
  );
};

export default AppSpinner;
