import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './reducers';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'general', 'userNotification'],
};

// const middlewares = [];

// if (process.env.NODE_ENV === 'dev') {
//   const { createLogger } = require(`redux-logger`);
//   const logger = createLogger({
//     collapsed: (getState, action, logEntry) => !logEntry.error,
//   });

//   middlewares.push(logger);
// }

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  //   middleware: (getDefaultMiddleware) =>
  //     getDefaultMiddleware({
  //       serializableCheck: false,
  //     }).concat(middlewares),
  devTools: process.env.REACT_APP_NODE_ENV !== 'prod',
});

const persistor = persistStore(store);

export { store, persistor };
