import React from 'react';
import { Link } from 'react-router-dom';

const AppCard = ({ items = [] }) => {
  return (
    <div className='app__main-card-container'>
      {items.map((ele) => (
        <div className='app__card-container' key={ele.id}>
          <div className='app__card-body'>
            <div>
              <img src={ele.image} alt={ele.id} />
            </div>
            <Link className='app__card-body-text' to={ele.link}>
              {ele.text}
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AppCard;
