import React from 'react';
import { Spinner } from 'react-bootstrap';

const Button = ({ onClick, className, children, disabled, loading, ...rest }) => {
  return (
    <button className={`app-btn ${className}`} onClick={onClick} disabled={disabled} {...rest}>
      {loading ? <Spinner size='sm' /> : children}
    </button>
  );
};

export default Button;
