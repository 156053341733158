import { combineReducers } from '@reduxjs/toolkit';
import { logout } from '../services/logoutService';
import authSlice from '../pages/auth/authSlice';
import generalSlice from './generalSlice';
import locationSlice from './locationSlice';
import NotificationSlice from '../pages/notice/NotificationSlice';

const combinedReducer = combineReducers({
  auth: authSlice,
  general: generalSlice,
  allLocation: locationSlice,
  userNotification: NotificationSlice,
});

const rootReducer = (state, action) => {
  if (action.type === logout.type) {
    state = { ...state, auth: undefined };
  }

  return combinedReducer(state, action);
};

export default rootReducer;
