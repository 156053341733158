import React from 'react';
import Form from 'react-bootstrap/Form'; //for toggle
import Accordion from 'react-bootstrap/Accordion';
import Nav from 'react-bootstrap/Nav';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { appLanguages } from '../../constants/appConstants';
import {
  log_icon,
  report_icon,
  settings_icon,
  dashboard_icon,
  survey_leftbar_icon,
  planing_leftbar_icon,
  holiday_leftbar_icon,
  academic_leftbar_icon,
  clipboard_leftbar_icon,
  help_center_leftbar_icon,
  announcement_leftbar_icon,
} from '../../assets/index.js';
import { changeLanguage, generalReducer } from '../../redux/generalSlice';
import { appRoutes } from '../../constants/appRoutes';
import { menuItems, PERMISSIONS } from '../../constants/appConstants';
import PermissionWrapper from '../pattern/PermissionWrapper.js';

const Leftbar = ({ showMenu, activeMenu, setActiveMenu }) => {
  const ZONE_ID = process.env.REACT_APP_DASHBOARD_ISAS_CAT_LIST_ZONE_ID || 188;
  const { appLanguage } = useSelector(generalReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const handleSwitchChange = () => {
    if (appLanguage === appLanguages.Bengali) {
      dispatch(changeLanguage(appLanguages.English));
      return;
    }
    dispatch(changeLanguage(appLanguages.Bengali));
  };

  return (
    <>
      <Nav
        activeKey={activeMenu}
        defaultActiveKey={menuItems.dashboard}
        className={showMenu ? 'leftbar-wrapper leftbar-show ' : 'leftbar-wrapper  leftbar-hide'}
      >
        <div className='width-leftbar d-flex flex-column '>
          <div className='d-flex flex-column px-2 px-lg-3 gap-3 py-3 h-100'>
            {/* 1st dashboard */}
            <Nav.Item className='nav-item-padding'>
              <Nav.Link
                eventKey={menuItems.dashboard}
                className='ps-0'
                onClick={() => {
                  setActiveMenu(menuItems.dashboard);
                  navigate(appRoutes.dashboard);
                }}
              >
                <div className='d-flex gap-2'>
                  <img src={dashboard_icon} className='leftbar_icon_size' />
                  <span className='mt-1'>{t('leftbar.dashboard')} </span>
                </div>
              </Nav.Link>
            </Nav.Item>

            {/* 2nd plan session settings */}
            <PermissionWrapper permissionName={PERMISSIONS.MANAGE_PLAN_SESSION}>
              <Nav.Item className='nav-item-padding'>
                <Nav.Link
                  eventKey={menuItems.planSessionSettings}
                  className='ps-0'
                  onClick={() => {
                    setActiveMenu(menuItems.planSessionSettings);
                    navigate(appRoutes.sessionList);
                  }}
                >
                  <div className='d-flex gap-2'>
                    <img src={settings_icon} className='leftbar_icon_size' />
                    <span className='mt-1'>{t('leftbar.planSessionSettings')}</span>
                  </div>
                </Nav.Link>
              </Nav.Item>
            </PermissionWrapper>

            {/* 3rd holiday list */}
            <Nav.Item className='nav-item-padding'>
              <Nav.Link
                eventKey={menuItems.holiday}
                className='ps-0'
                onClick={() => {
                  setActiveMenu(menuItems.holiday);
                  navigate(appRoutes.holiday(new Date().getFullYear()));
                }}
              >
                <div className='d-flex gap-2'>
                  <img src={holiday_leftbar_icon} className='leftbar_icon_size' />
                  <span className='mt-1'>{t('leftbar.holiday')}</span>
                </div>
              </Nav.Link>
            </Nav.Item>

            {/* 4th monitoring plan */}
            <Accordion>
              <Accordion.Item eventKey={menuItems.visitPlan.name}>
                <Accordion.Header>
                  <div className='d-flex gap-2'>
                    <img src={planing_leftbar_icon} className='leftbar_icon_size' />
                    <span className='mt-1'>{t('leftbar.visitPlan')}</span>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <PermissionWrapper permissionName={PERMISSIONS.SUBMIT_VISIT_PLAN}>
                    <Nav.Item>
                      <Nav.Link
                        eventKey={menuItems.visitPlan.myPlan}
                        onClick={() => {
                          setActiveMenu(menuItems.visitPlan.myPlan);
                          navigate(appRoutes.myPlan(new Date().getFullYear()));
                        }}
                      >
                        <div className='d-flex gap-1 px-2'>{t('leftbar.myPlan')}</div>
                      </Nav.Link>
                    </Nav.Item>
                  </PermissionWrapper>
                  <PermissionWrapper permissionName={PERMISSIONS.SUPERVISE_SUBMITTED_PLAN}>
                    <Nav.Item>
                      <Nav.Link
                        eventKey={menuItems.visitPlan.yearlyPlanSubmissionStats}
                        onClick={() => {
                          setActiveMenu(menuItems.visitPlan.yearlyPlanSubmissionStats);
                          navigate(appRoutes.submissionStat(new Date().getFullYear()));
                        }}
                      >
                        <div className='d-flex gap-1 px-2'>
                          {t('leftbar.yearlyPlanSubmissionStats')}
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey={menuItems.visitPlan.yearlyPlanSubmissionISASWise}
                        onClick={() => {
                          setActiveMenu(menuItems.visitPlan.yearlyPlanSubmissionISASWise);
                          navigate(
                            appRoutes.isasComplaintDetails(new Date().getFullYear(), ZONE_ID),
                          );
                        }}
                      >
                        <div className='d-flex gap-1 px-2'>
                          {t('leftbar.yearlyPlanSubmissionISASWise')}
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                  </PermissionWrapper>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            {/* 5th monitorings */}
            <PermissionWrapper permissionName={PERMISSIONS.MANAGE_SUBMITTED_VISIT}>
              <Accordion>
                <Accordion.Item eventKey={menuItems.monitorings.name}>
                  <Accordion.Header>
                    <div className='d-flex gap-2'>
                      <img src={clipboard_leftbar_icon} className='leftbar_icon_size' />
                      <span className='mt-1'> {t('leftbar.monitorings')}</span>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Nav.Item>
                      <Nav.Link
                        eventKey={menuItems.monitorings.classroomMonitoring}
                        onClick={() => {
                          setActiveMenu(menuItems.monitorings.classroomMonitoring);
                          navigate(appRoutes.curriculumVisits);
                        }}
                      >
                        {' '}
                        <div className='d-flex gap-1 px-2'>{t('leftbar.classroomMonitoring')}</div>
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link
                        eventKey={menuItems.monitorings.institutionalMonitoring}
                        onClick={() => {
                          setActiveMenu(menuItems.monitorings.institutionalMonitoring);
                          navigate(appRoutes.generalVisits);
                        }}
                      >
                        {' '}
                        <div className='d-flex gap-1 px-2'>
                          {t('leftbar.institutionalMonitoring')}
                        </div>
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link
                        eventKey={menuItems.monitorings.officeMonitoring}
                        onClick={() => {
                          setActiveMenu(menuItems.monitorings.officeMonitoring);
                          navigate(appRoutes.officeVisits);
                        }}
                      >
                        {' '}
                        <div className='d-flex gap-1 px-2'>{t('leftbar.officeMonitoring')}</div>
                      </Nav.Link>
                    </Nav.Item>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </PermissionWrapper>

            {/* 6th monitoring BI */}
            <PermissionWrapper permissionName={PERMISSIONS.VIEW_BI_REPORT}>
              <Nav.Item className='nav-item-padding'>
                <Nav.Link
                  eventKey={menuItems.monitoringBI}
                  className='ps-0'
                  onClick={() => {
                    setActiveMenu(menuItems.monitoringBI);
                    navigate(appRoutes.monitoringBI);
                  }}
                >
                  <div className='d-flex gap-2'>
                    <img src={survey_leftbar_icon} className='leftbar_icon_size' />
                    <span className='mt-1'>{t('leftbar.monitoringBI')}</span>
                  </div>
                </Nav.Link>
              </Nav.Item>
            </PermissionWrapper>

            {/* 7th report */}
            <PermissionWrapper permissionName={PERMISSIONS.VIEW_BI_REPORT}>
              <Accordion>
                <Accordion.Item eventKey={menuItems.report.name}>
                  <Accordion.Header>
                    <div className='d-flex gap-2'>
                      <img src={report_icon} className='leftbar_icon_size' />
                      <span className='mt-1'>{t('leftbar.report')}</span>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Nav.Item>
                      <Nav.Link
                        eventKey={menuItems.report.institution}
                        onClick={() => {
                          setActiveMenu(menuItems.report.institution);
                          navigate(appRoutes.institutionReport);
                        }}
                      >
                        <div className='d-flex gap-1 px-2'>{t('leftbar.institution')}</div>
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link
                        eventKey={menuItems.report.office}
                        onClick={() => {
                          setActiveMenu(menuItems.report.office);
                          navigate(appRoutes.officeReport);
                        }}
                      >
                        <div className='d-flex gap-1 px-2'>{t('leftbar.office')}</div>
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link
                        eventKey={menuItems.report.curriculum}
                        onClick={() => {
                          setActiveMenu(menuItems.report.curriculum);
                          navigate(appRoutes.curriculumReport);
                        }}
                      >
                        <div className='d-flex gap-1 px-2'>{t('leftbar.curriculum')}</div>
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link
                        eventKey={menuItems.report.others}
                        onClick={() => {
                          setActiveMenu(menuItems.report.others);
                          navigate(appRoutes.othersReport);
                        }}
                      >
                        <div className='d-flex gap-1 px-2'>{t('leftbar.others')}</div>
                      </Nav.Link>
                    </Nav.Item>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </PermissionWrapper>

            {/* 8th institute list */}
            <PermissionWrapper permissionName={PERMISSIONS.VIEW_INSTITUTE_DETAIL}>
              <Nav.Item className='nav-item-padding'>
                <Nav.Link
                  eventKey={menuItems.instituteList}
                  className='ps-0'
                  onClick={() => {
                    setActiveMenu(menuItems.instituteList);
                    navigate(appRoutes.instituteList);
                  }}
                >
                  <div className='d-flex gap-2'>
                    <img src={academic_leftbar_icon} className='leftbar_icon_size' />
                    <span className='mt-1'>{t('leftbar.instituteList')}</span>
                  </div>
                </Nav.Link>
              </Nav.Item>
            </PermissionWrapper>

            {/* 9th officer list */}
            <PermissionWrapper permissionName={PERMISSIONS.VIEW_OFFICER_DETAIL}>
              <Nav.Item className='nav-item-padding'>
                <Nav.Link
                  eventKey={menuItems.officeList}
                  className='ps-0'
                  onClick={() => {
                    setActiveMenu(menuItems.officeList);
                    navigate(appRoutes.officerList);
                  }}
                >
                  <div className='d-flex gap-2'>
                    <img src={survey_leftbar_icon} className='leftbar_icon_size' />
                    <span className='mt-1'>{t('leftbar.officerList')}</span>
                  </div>
                </Nav.Link>
              </Nav.Item>
            </PermissionWrapper>

            {/* 10th notice */}
            <Accordion>
              <Accordion.Item eventKey={menuItems.report.name}>
                <Accordion.Header>
                  <div className='d-flex gap-2'>
                    <img src={announcement_leftbar_icon} className='leftbar_icon_size' />
                    <span className='mt-1'>{t('leftbar.notice')}</span>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <Nav.Link
                    eventKey={menuItems.noticeList}
                    onClick={() => {
                      setActiveMenu(menuItems.noticeList);
                      navigate(appRoutes.noticeList);
                    }}
                  >
                    <div className='d-flex gap-1 px-2'>{t('leftbar.noticeList')}</div>
                  </Nav.Link>
                  <PermissionWrapper permissionName={PERMISSIONS.MANAGE_NOTICE}>
                    <Nav.Item>
                      <Nav.Link
                        eventKey={menuItems.noticeManagement}
                        onClick={() => {
                          setActiveMenu(menuItems.noticeManagement);
                          navigate(appRoutes.noticeManagement);
                        }}
                      >
                        <div className='d-flex gap-1 px-2'>{t('leftbar.noticeManagement')}</div>
                      </Nav.Link>
                    </Nav.Item>
                  </PermissionWrapper>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            {/* 11th notice settings */}
            <PermissionWrapper permissionName={PERMISSIONS.MANAGE_SYSTEM_SETTINGS}>
              <Nav.Item className='nav-item-padding'>
                <Nav.Link
                  eventKey={menuItems.settings}
                  onClick={() => {
                    setActiveMenu(menuItems.settings);
                    navigate(appRoutes.settings);
                  }}
                  className='ps-0'
                >
                  <div className='d-flex gap-2'>
                    <img src={settings_icon} alt='settings-icon' />
                    <span className='mt-1'>{t('leftbar.settings')}</span>
                  </div>
                </Nav.Link>
              </Nav.Item>
            </PermissionWrapper>

            {/* 12th audit log */}
            <PermissionWrapper permissionName={PERMISSIONS.MANAGE_AUDIT}>
              <Nav.Item className='nav-item-padding'>
                <Nav.Link
                  eventKey={menuItems.auditLog}
                  onClick={() => {
                    setActiveMenu(menuItems.auditLog);
                    navigate(appRoutes.auditLog);
                  }}
                  className='ps-0'
                >
                  <div className='d-flex gap-2'>
                    <img src={log_icon} alt='announcement-icon' />
                    <span className='mt-1'>{t('leftbar.auditLog')}</span>
                  </div>
                </Nav.Link>
              </Nav.Item>
            </PermissionWrapper>

            {/* 13th help center */}
            <Nav.Item className='nav-item-padding'>
              <Nav.Link
                eventKey={menuItems.helpCenter}
                onClick={() => {
                  setActiveMenu(menuItems.helpCenter);
                  navigate(appRoutes.helpCenter);
                }}
                className='ps-0'
              >
                <div className='d-flex gap-2'>
                  <img src={help_center_leftbar_icon} alt='leftbar_icon_size' />
                  <span className='mt-1'>{t('leftbar.helpCenter')}</span>
                </div>
              </Nav.Link>
            </Nav.Item>
          </div>
        </div>
      </Nav>

      {/* language toggle */}

      <div
        className={
          showMenu
            ? 'language-toggle-container language-toggle-container-show '
            : 'language-toggle-container language-toggle-container-hide'
        }
      >
        <div className='d-flex flex-column '>
          <Form className='d-flex  justify-content-center  align-items-center fs-6  border-top px-4 px-lg-5'>
            <label
              className='language-toggle-text mt-2 text-decoration-none'
              htmlFor='languageChangeSwitch'
            >
              English
            </label>
            <Form.Check
              type='switch'
              checked={appLanguage === appLanguages.Bengali}
              className='mt-2 ms-2'
              onChange={handleSwitchChange}
              id='languageChangeSwitch'
            />
            <label
              className='language-toggle-text mt-2 text-decoration-none'
              htmlFor='languageChangeSwitch'
            >
              বাংলা
            </label>
          </Form>
          <span className='p-1 text-center fw-light'>
            <span className='text-small'>
              All rights reserved by directorate of secondary and higher education{' '}
            </span>
          </span>
        </div>
      </div>
    </>
  );
};

export default Leftbar;
