import React, { Fragment, useRef } from 'react';
import Button from './Button';
import InputField from './InputField';
import { add_icon_white } from '../../assets';

const FileUpload = ({
  onFileSelect,
  hideInputField,
  value,
  buttonText = 'Select File',
  disableButton,
}) => {
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
    fileInputRef.current.value = null;
  };

  return (
    <Fragment>
      <input
        ref={fileInputRef}
        id='fileInput'
        type='file'
        style={{ display: 'none' }}
        onChange={onFileSelect}
      />
      <div className={`d-flex ${hideInputField && 'justify-content-end w-100'} `}>
        <div className={`wd-30 ${hideInputField && 'd-none'}`}>
          <InputField
            className='file-upload__input'
            type='text'
            placeholder={buttonText}
            value={value ? value : ''}
            readOnly
            onClick={handleButtonClick}
          />
        </div>
        <div>
          <Button
            className={`dms-bg-primary fw-bold text-white  ${
              !hideInputField && 'file-upload__button'
            } ${disableButton && 'dms-bg-disable'} `}
            onClick={handleButtonClick}
            disabled={disableButton}
          >
            <div className='d-flex gap-2'>
              <div>
                <img src={add_icon_white} />
              </div>
              <div>{buttonText}</div>
            </div>
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

export default FileUpload;
