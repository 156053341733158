import React from 'react';

import { authInfo } from '../../pages/auth/authSlice';
import { useSelector } from 'react-redux';

const PermissionWrapper = ({ children, permissionName, fallback }) => {
  const {
    user: { activeJurisdiction },
  } = useSelector(authInfo);

  if (activeJurisdiction.permissions.includes(permissionName)) return <>{children}</>;
  return <>{fallback}</>;
};

export default PermissionWrapper;
