import React from 'react';
import { ButtonGroup } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { filter_down_arrow } from '../../assets/index';

const AppDropdownButton = ({ className, title, items = [], handleItemClick = () => {} }) => {
  return (
    <DropdownButton
      className={`app__dropdown-btn ${className}`}
      as={ButtonGroup}
      title={
        <span>
          {title} <img src={filter_down_arrow} alt='down-arrow' />
        </span>
      }
    >
      {items.map((item, index) => (
        <Dropdown.Item key={index} eventKey={item.type} onClick={() => handleItemClick(item.type)}>
          {item.label}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
};

export default AppDropdownButton;
