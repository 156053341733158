import React from 'react';

const ServerError = () => {
  return (
    <main className='server_error_main'>
      <div className='server_error_wrap'>
        <div className='server_error_hand server_error_hand-left'>
          <span className='server_error_hand-part server_error_part-top'></span>
          <span className='server_error_hand-part server_error_part-middle'></span>
          <span className='server_error_hand-part server_error_part-bottom'></span>
        </div>
        <div className='server_error_hand server_error_hand-right'>
          <span className='server_error_hand-part server_error_part-top'></span>
          <span className='server_error_hand-part server_error_part-middle'></span>
          <span className='server_error_hand-part server_error_part-bottom'></span>
        </div>
        <div className='server_error_line server_error_line-1'>
          <div className='server_error_ball'>5</div>
        </div>
        <div className='server_error_line server_error_line-2'>
          <div className='server_error_ball'>0</div>
        </div>
        <div className='server_error_line server_error_line-3'>
          <div className='server_error_ball'>0</div>
        </div>
        <div className='server_error_server'>
          <div className='server_error_eye server_error_eye-left'>
            <span></span>
          </div>
          <div className='server_error_eye server_error_eye-right'>
            <span></span>
          </div>
          <div className='server_error_block'>
            <div className='server_error_light'></div>
          </div>
          <div className='server_error_block'>
            <div className='server_error_light'></div>
          </div>
          <div className='server_error_block'>
            <div className='server_error_light'></div>
          </div>
          <div className='server_error_block'>
            <div className='server_error_light'></div>
          </div>
          <div className='server_error_block'>
            <div className='server_error_light'></div>
          </div>
          <div className='server_error_bottom-block'>
            <div className='server_error_bottom-line'></div>
            <div className='server_error_bottom-light'></div>
          </div>
        </div>
      </div>

      <div className='server_error_code-error'>
        <h1>Internal Server Error!</h1>
      </div>
    </main>
  );
};

export default ServerError;
